/* eslint tailwindcss/no-custom-classname: off */
import { useState, useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { getPlaceholder } from '@/features/global/util'

interface SearchBoxProps {
  keyword?: string
}

export const SearchBox: React.FC<SearchBoxProps> = ({ keyword = '' }) => {
  const [value, setValue] = useState(keyword)
  const [placeholder, setPlaceholder] = useState('')

  useEffect(() => {
    setPlaceholder(getPlaceholder(1, isMobile, false))
  }, [])

  return (
    <div id="searchbox" className="pc">
      <form id="search" method="get" action="/search">
        <input
          type="text"
          className="form-control"
          name="keyword"
          value={value}
          placeholder={placeholder}
          onChange={(e) => setValue(e.target.value)}
        />
        <button type="submit" className="btn btn-xs btn-default"></button>
      </form>
    </div>
  )
}
