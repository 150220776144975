/* eslint tailwindcss/no-custom-classname: off */
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import {
  initialHeaderState,
  setOpen,
  AppDispatch,
  useGetUserInfoQuery,
} from '@/stores'
import { Header } from '../Header'
import { ModalBackdrop } from '../Modal/ModalBackdrop'
import { LoginError } from '../Modal/LoginError'
import { Footer } from '../Footer/Footer'
import { LoginForm } from '../LoginForm'
import { TopLoadingBar } from './TopLoadingBar'
import { IeModal } from '../Modal'
import { postGetSession } from '@/api/user'
import { TutorialModal } from '../Modal/TutorialModal'
import { useGoogleAnalytics } from '@/hooks/useGoogleAnalytics'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorFallback from './ErrorFallback'
import { useAccessLog } from '@/hooks/useAcessLog'

interface Props {
  children?: React.ReactNode
}

export const Layout: React.FC<Props> = ({ children }) => {
  const dispatch = useDispatch<AppDispatch>()
  useGoogleAnalytics()
  useAccessLog()

  const { data: user, refetch } = useGetUserInfoQuery()

  useEffect(() => {
    // 旧フロントからのログイン状態を引き継ぐため、PHPSESSIDでAPI_KEY, SECRETを取得し保存
    ;(async () => {
      await postGetSession()
      refetch()
    })()
  }, [user])

  const handleClick = () => {
    dispatch(setOpen(initialHeaderState.open))
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <div onClick={handleClick}>
        <TopLoadingBar />
        <Header />
        <section>
          <div>{children}</div>
        </section>
        <Footer />
        <LoginForm />
        <ModalBackdrop />
        <LoginError />
        <IeModal />
        <TutorialModal />
      </div>
    </ErrorBoundary>
  )
}
