/* eslint tailwindcss/no-custom-classname: off */
import { useSelector, useDispatch } from 'react-redux'
import { modalFadeOut, RootState, setOpen } from '@/stores'

export const LoginError: React.FC = () => {
  const openState = useSelector((state: RootState) => state.header.open)
  const dispatch = useDispatch()

  const handleClick = (e) => {
    e.stopPropagation()
    dispatch(
      setOpen({
        ...openState,
        modalLoginError: modalFadeOut,
      })
    )
  }

  const handleClickModal = (e) => {
    e.stopPropagation()
  }

  return (
    <div
      className={openState.modalLoginError.class}
      role="dialog"
      tabIndex={-1}
      style={openState.modalLoginError.css}
      onClick={handleClickModal}
    >
      <div className="modal-dialog" role="document">
        <div className="relative bg-clip-padding bg-white rounded-none border border-[#00000033] border-solid outline-none shadow-[0_3px_9px_#00000080] sm:shadow-[0_5px_15px_#00000080]">
          <div className="relative p-[15px]">
            <form className="relative">
              <button
                type="button"
                className="float-right p-0 text-[21px] font-bold leading-4 text-fl-black hover:no-underline border-0 opacity-20 hover:opacity-50 cursor-pointer hover:cursor-pointer shadow-[0_1px_0_#fff]"
                data-dismiss="modal"
                onClick={handleClick}
              >
                &times;
              </button>
              <p>ログインできませんでした。</p>
              <p>メールアドレス・パスワードをご確認ください。</p>
              <div className="mx-[-15px] mt-[30px] mb-[15px] text-center">
                <button
                  type="button"
                  className="inline-block relative py-[6px] px-3 pb-1 mb-0 text-sm font-normal leading-5 text-center text-white hover:text-white hover:no-underline align-middle whitespace-nowrap bg-[#337ab7] hover:bg-[#286090] bg-none rounded border border-[#2e6da4] hover:border-[#204d74] border-solid cursor-pointer hover:outline-none select-none touch-manipulation"
                  onClick={handleClick}
                >
                  OK
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}
