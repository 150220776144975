/* eslint tailwindcss/no-custom-classname: off */
import { OpenInNewIcon } from '@/components/icon'
import { IconArrow } from '@/components/icon/IconArrow'
import { useUser } from '@/hooks/useUser'

export const BizButton: React.FC = () => {
  const user = useUser()

  return user?.biz_group_id > 0 ? null : (
    <button
      className="py-3 sm:py-[5px] px-4 sm:px-[15px] w-full sm:w-min h-[42px] sm:h-9 text-fl-blue whitespace-nowrap text-label-large-bold"
      onClick={() => {
        window.open(
          'https://biz.flierinc.com/?utm_source=flierinc.com&utm_medium=referral&utm_campaign=flierinc.com',
          '_blank'
        )
      }}
    >
      <div className="flex gap-[5px] justify-between sm:justify-center items-center">
        法人導入はこちら
        <OpenInNewIcon className="pc fill-fl-blue" />
        <IconArrow className="w-4 h-4 sp fill-fl-blue" />
      </div>
    </button>
  )
}
