import { useContext, useEffect } from 'react'
import { TabContext } from './Tabs'

export interface TabValue {
  label: string
  index: number
  icon?: React.ReactNode
}

interface Props extends TabValue {
  children?: React.ReactNode
}

export const Tab: React.FC<Props> = ({ label, icon, index, children }) => {
  const { active, addTab } = useContext(TabContext)
  const isActive = index === active
  useEffect(() => {
    addTab({ label, icon, index })
  }, [])
  return isActive ? <>{children}</> : null
}
