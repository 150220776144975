import Link from 'next/link'
import { zeroPadding, chunk } from '@/utils/format'
import { CategoryIcon } from '@/components/icon/CategoryIcon'
interface Item {
  no: number
  title: string
}

const items: Item[] = [
  { no: 1, title: 'スキルアップ・キャリア' },
  { no: 2, title: '自己啓発・マインド' },
  { no: 3, title: '生産性・時間管理' },
  { no: 4, title: 'リーダーシップ・マネジメント' },
  { no: 5, title: '経営戦略' },
  { no: 6, title: '起業・イノベーション' },
  { no: 7, title: '人事' },
  { no: 8, title: 'マーケティング' },
  { no: 9, title: '産業・業界' },
  { no: 10, title: 'グローバル' },
  { no: 11, title: '政治・経済' },
  { no: 12, title: 'ファイナンス' },
  { no: 13, title: 'テクノロジー・IT' },
  { no: 14, title: 'サイエンス' },
  { no: 15, title: 'リベラルアーツ' },
  { no: 16, title: '健康・フィットネス' },
  { no: 17, title: 'トレンド' },
]

export const Categories: React.FC = () => {
  return (
    <ul className="inline">
      {chunk(items, 5).map((chunkItems, i) => (
        <li key={i}>
          <ul>
            {chunkItems.map((item) => (
              <Link href={`/category/${item.no}`} key={item.no}>
                <div
                  className="whitespace-nowrap"
                  id={`cat${zeroPadding(item.no, 2)}`}
                >
                  <li>
                    <span className="flex items-center cursor-pointer">
                      <span className="mr-2">
                        <CategoryIcon
                          categoryId={item.no}
                          svgProps={{
                            viewBox: '0 0 128 128',
                            width: 25,
                            height: 25,
                          }}
                        />
                      </span>
                      <span>{item.title}</span>
                    </span>
                  </li>
                </div>
              </Link>
            ))}
          </ul>
        </li>
      ))}
    </ul>
  )
}
