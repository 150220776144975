import ReactModal from 'react-modal'
import _JSXStyle from 'styled-jsx/style'
import { CloseButton } from '../button/CloseButton'

// アニメーションなどreact-modalのクラスに直接当てたいものはここに書く
const styles = `
  .ReactModal__Body--open {
    overflow: hidden;
  }

  .ReactModal__Overlay {
  }

  .ReactModal__Overlay--after-open{
  }

  .ReactModal__Overlay--before-close{
  }

  .ReactModal__Content {
    max-height: 80%;
    overflow-y: auto;
    position: relative;
    opacity: 0;
    transform: translate(-50%, -20px);
    transition: all 300ms ease-in-out;
  }

  .ReactModal__Content--after-open {
    transform: translate(-50%, 0);
    opacity: 1;
  }

  .ReactModal__Content--before-close {
    transform: translate(-50%, -20px);
    opacity: 0;
  }

  @media (max-device-width: 767px) {
    .ReactModal__Content {
      width: 95% !important;
    }
  }
`

export const initialStyles: Style = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 100000,
  },
  content: {
    top: '10%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    width: '50%',
    padding: '0px',
  },
}

export interface Style {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  overlay: Record<string, any>
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  content: Record<string, any>
}

interface Props {
  open: boolean
  showClose?: boolean
  onAfterOpen?: () => void
  onRequestClose?: () => void
  style?: Style
  styleDirect?: string
  closeTimeoutMS?: number
  children: React.ReactNode
}
export const Modal: React.FC<Props> = ({
  open,
  showClose = false,
  onAfterOpen,
  onRequestClose,
  style = initialStyles,
  closeTimeoutMS = 200,
  children,
  styleDirect = '',
}) => {
  return (
    <ReactModal
      isOpen={open}
      onAfterOpen={onAfterOpen}
      onRequestClose={onRequestClose}
      style={style}
      closeTimeoutMS={closeTimeoutMS}
      ariaHideApp={false}
    >
      {showClose && (
        <div className="absolute right-0">
          <CloseButton onClick={onRequestClose} />
        </div>
      )}
      {children}
      <_JSXStyle>
        {styles}
        {styleDirect}
      </_JSXStyle>
    </ReactModal>
  )
}
