import { ArrowRightSmall, MenuFeature } from '@/components/icon'
import Link from 'next/link'

interface Props {
  onClick: () => void
}
export const Feature: React.FC<Props> = ({ onClick }) => {
  // 特集項目
  const features = [
    {
      title: '動画',
      path: '/channel?from=header_sp',
    },
    {
      title: 'いま読んでおきたい話題書',
      path: '/summary/masterpiece',
    },
    {
      title: '無料で読める要約',
      path: '/summary/free',
    },
    {
      title: 'インタビュー',
      path: '/interview/list',
    },
    {
      title: 'スペシャルコラム',
      path: '/column/list',
    },
    {
      title: 'イチオシの本',
      path: '/pickup/list',
    },
    {
      title: 'おすすめ要約診断',
      path: '/diagnosis/list',
    },
  ]

  return (
    <>
      <div className="border-b-8 border-fl-gray1">
        <div className="flex flex-none flex-grow-0 order-1 py-3 pr-0 pl-4 m-0">
          <MenuFeature className="pr-2" />
          <span className="font-flier text-[10px] xs:text-xs not-italic font-bold tracking-tight leading-relaxed text-fl-black">
            特集
          </span>
        </div>
        <div className="flex flex-row flex-wrap items-start p-0">
          {features.map((item, i) => {
            return (
              <Link
                key={`search-form-feature-${i}`}
                href={item.path}
                className="flex justify-between py-3 pr-[18px] pl-4 w-1/2 border-0 border-t border-r border-fl-gray1"
                onClick={onClick}
              >
                <span className="flex-none flex-grow-0 order-none font-flier text-[10px] xs:text-xs not-italic font-normal leading-relaxed text-fl-dark">
                  {item.title}
                </span>
                <ArrowRightSmall color="#666" />
              </Link>
            )
          })}
        </div>
      </div>
    </>
  )
}
