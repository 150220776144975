/**
 * URLにマッチするかどうかを判定する
 */
export function checkUrlMatches(url): boolean {
  for (const targetUrl of targetUrls) {
    const regex = new RegExp('^' + targetUrl)
    if (regex.test(url)) {
      return true
    }
  }
  return false
}

const targetUrls = ['/reading-programs/[0-9]+$', '/user/regist']
