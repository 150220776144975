import { useEffect, useState } from 'react'
import { isDesktop } from 'react-device-detect'

export const IeModal: React.FC = () => {
  const [show, setShow] = useState(false)

  useEffect(() => {
    // PCのみ表示
    setShow(isDesktop)
  }, [])

  return (
    show && (
      <div
        id="ieModal"
        style={{
          display: 'none',
          position: 'fixed',
          zIndex: 10000,
          left: 0,
          top: 0,
          overflow: 'auto',
          backgroundColor: 'rgba(0,0,0,0.5)',
          width: '100%',
          height: '100%',
        }}
      >
        <div className="m-auto mt-20 sm:w-[45%] sm:h-[75%]">
          <img
            src={process.env.IMG_SERVER + '/top/pc_popup_memo.png'}
            alt=""
            className="w-full h-full"
          />
        </div>
      </div>
    )
  )
}
