import { Dispatch, SetStateAction } from 'react'
import css from 'styled-jsx/css'
import { Category, Feature, Ranking, Search } from '.'
const styles = css`
  @keyframes slideDown {
    0% {
      opacity: 0;
      transform: translateY(-100%);
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }
  @keyframes slideUp {
    0% {
      opacity: 1;
      transform: translateY(0%);
    }
    100% {
      opacity: 0;
      transform: translateY(-100%);
    }
  }
  #searchModal.up {
    animation: slideUp 0.35s ease-in-out forwards;
  }
  #searchModal.down {
    animation: slideDown 0.35s ease-in-out forwards;
  }
`
interface Props {
  isShowModal: boolean
  isModalOpen: boolean
  setModalOpen: Dispatch<SetStateAction<boolean>>
}

export const SearchForm: React.FC<Props> = ({
  isShowModal,
  isModalOpen,
  setModalOpen,
}) => {
  return (
    <div
      id="searchModal"
      tabIndex={-1}
      className={`${
        isShowModal ? '' : 'hidden'
      } overflow-x-hidden overflow-y-auto fixed top-0 right-0 left-0 z-50 ${
        isModalOpen ? 'down' : 'up'
      }`}
    >
      <div
        className="overflow-y-scroll relative p-0 w-auto max-h-screen"
        role="document"
      >
        <div className="relative bg-clip-padding bg-white rounded-none border">
          <Search setModalOpen={setModalOpen} />
          <div className="relative p-0">
            <Ranking onClick={() => setModalOpen(false)} />
            <Feature onClick={() => setModalOpen(false)} />
            <Category onClick={() => setModalOpen(false)} />
          </div>
        </div>
      </div>
      <style jsx>{styles}</style>
    </div>
  )
}
