/* eslint tailwindcss/no-custom-classname: off */
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'
import { BreadcrumbJsonLd } from 'next-seo'
import { RootState } from '@/stores/store'
import { Space } from '@/components/layout/Space'
import { HomeIcon } from '@/components/icon'
import { BizButton } from './BizButton'

export const Breadcrumb: React.FC = () => {
  const router = useRouter()
  const items = useSelector((state: RootState) => state.breadcrumb.items)
  if (items === null) return <div className="h-[3px] bg-[#3069b0] pc"></div>
  return (
    <>
      {items.length > 0 && (
        <BreadcrumbJsonLd
          itemListElements={[
            {
              position: 1,
              name: '本の要約サービス flier(フライヤー)',
              item: process.env.WWW_SERVER,
            },
            ...items.map((item, i) => {
              return {
                position: i + 2,
                name: item.name,
                item: item.url ?? router.asPath,
              }
            }),
          ]}
        />
      )}
      <div className="flexbox pc" id="header-menu-border"></div>
      <div className="bg-fl-white flexbox pc" id="breadcrumb">
        <Space />
        <div className="container flex">
          <ul className="flexbox">
            <li>
              <div className="items-center flexbox">
                <HomeIcon className="w-[16px] h-[16px] text-[#1A6DCC]" />
                <Link href="/">
                  <div
                    className={
                      items.length > 0
                        ? 'arrow-right-gray indent-1'
                        : 'indent-1'
                    }
                  >
                    本の要約サービス flier(フライヤー)
                  </div>
                </Link>
              </div>
            </li>
            {items.map((breadcrumb, i) => {
              return (
                <li key={`${i}-${breadcrumb.name}`}>
                  {breadcrumb.url ? (
                    <Link href={breadcrumb.url}>
                      <div
                        className={
                          i + 1 !== items.length ? 'arrow-right-gray' : ''
                        }
                      >
                        {breadcrumb.name}
                      </div>
                    </Link>
                  ) : (
                    <span
                      className={
                        i + 1 !== items.length ? 'arrow-right-gray' : ''
                      }
                    >
                      {breadcrumb.name}
                    </span>
                  )}
                </li>
              )
            })}
          </ul>
          <BizButton></BizButton>
        </div>
        <Space />
      </div>
      <div className="border-fl-gray1 sp border-y">
        <BizButton></BizButton>
      </div>
    </>
  )
}
