/* eslint tailwindcss/no-custom-classname: off */
import { useState } from 'react'
import { isMobile, isTablet } from 'react-device-detect'
import { useUser } from '@/hooks/useUser'
import { LoginUserMenu, AnonymousUserMenu, SearchIcon } from './'
import { SearchForm } from '../../SearchForm'
import { isBizUser, isFacility, isLimitedUrlUser } from '@/utils/user'
import { CourseId } from '@/types'

export const UserTools: React.FC = () => {
  const user = useUser()
  const [isModalOpen, setModalOpen] = useState(false)
  const [isShowModal, setShowModal] = useState(false)
  if (!user) {
    return null
  }

  let className = 'course-non-user'
  if (user.course?.id === CourseId.free) {
    className = 'course-free'
  } else if (user.course?.id === CourseId.silver) {
    className = 'course-silver'
  } else if (user.course?.id === CourseId.gold) {
    className = 'course-gold'
  }

  if (isLimitedUrlUser(user.email)) {
    className += ' is-limited-url'
  } else if (isFacility(user.email)) {
    className += ' is-facility'
  } else if (isBizUser(user.biz_group_id)) {
    className += ' is-business'
  } else {
    className += ' is-not-business'
  }

  return (
    <div id="user-tools" className={className}>
      {isMobile && !isTablet && (
        <div className="mr-[11px]">
          <SearchIcon setModalOpen={setModalOpen} setShowModal={setShowModal} />
          <SearchForm
            isShowModal={isShowModal}
            isModalOpen={isModalOpen}
            setModalOpen={setModalOpen}
          />
        </div>
      )}
      {user?.result ? <LoginUserMenu user={user} /> : <AnonymousUserMenu />}
    </div>
  )
}
