import { Dispatch, SetStateAction, useState } from 'react'
import css from 'styled-jsx/css'
const styles = css`
  #sp-search-input {
    display: block;
    width: 100%;
    line-height: 1.4;
    color: #555;
    height: 44px;
    background: #fff;
    border: 2px solid #e7e7e7;
    box-shadow: none !important;
  }
  @media (max-device-width: 320px) {
    #sp-search-input {
      font-size: 12px !important;
    }
  }
  #searchButton {
    background-color: transparent;
    background-image: url(${process.env
      .IMG_SERVER}/common/icon_search_blue.svg);
    background-repeat: no-repeat;
    border: none;
    height: 20px;
    position: absolute;
    right: 20px;
    top: 65px;
    padding-bottom: 4px;
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
    cursor: pointer;
    padding: 6px 12px;
  }
  #closeButton {
    border: none;
    color: #666;
    font-size: 16px;
    margin: 0;
    padding: 0;
    opacity: 1;
    cursor: pointer;
    background: 0 0;
    float: right;
    font-weight: 700;
    line-height: 1;
  }
`
interface Props {
  setModalOpen: Dispatch<SetStateAction<boolean>>
}

export const Search: React.FC<Props> = ({ setModalOpen }) => {
  const [value, setValue] = useState('')
  const onClose = () => {
    setModalOpen(false)
  }
  return (
    <>
      <div className="static p-4 font-medium bg-transparent rounded-b-lg border-0 border-b-8 border-fl-gray1">
        <button id="closeButton" type="button" onClick={onClose}>
          &times;
        </button>
        <div>
          <p className="flex flex-none flex-shrink-0 order-none justify-center items-center mx-0 mt-0 mb-[15px] font-flier text-[17px] not-italic font-bold tracking-wide leading-snug text-fl-black">
            本を探す
          </p>
          <form id="sp_search" method="get" action="/search">
            <input
              id="sp-search-input"
              type="text"
              name="keyword"
              value={value}
              placeholder="キーワード、タイトル、著者名で検索"
              onChange={(e) => setValue(e.target.value)}
            />
            <button id="searchButton" type="submit"></button>
          </form>
        </div>
      </div>
      <style jsx>{styles}</style>
    </>
  )
}
