import Link from 'next/link'
import { ArrowRightSmall, MenuRanking } from '@/components/icon'
interface Props {
  onClick: () => void
}
export const Ranking: React.FC<Props> = ({ onClick }) => {
  // ランキング項目
  const rankings = [
    { title: '日間', path: 'daily' },
    { title: '週間', path: 'weekly' },
    { title: '月間', path: 'monthly' },
    { title: '年間', path: 'yearly' },
  ]

  return (
    <>
      <div className="border-b-8 border-fl-gray1">
        <div className="flex flex-none flex-grow-0 order-1 py-3 pr-0 pl-4 m-0">
          <MenuRanking className="pr-2" />
          <span className="font-flier text-[10px] xs:text-xs not-italic font-bold tracking-tight leading-relaxed text-fl-black">
            ランキング
          </span>
        </div>
        <div className="flex flex-row flex-wrap items-start p-0">
          {rankings.map((item, i) => {
            return (
              <Link
                key={`search-form-ranking-${i}`}
                href={`/summary/ranking?tab=${item.path}`}
                className="flex justify-between py-3 pr-[18px] pl-4 w-1/2 border-0 border-t border-r border-fl-gray1"
                onClick={onClick}
              >
                <span className="flex-none flex-grow-0 order-none font-flier text-[10px] xs:text-xs not-italic font-normal leading-relaxed text-fl-dark">
                  {item.title}
                </span>
                <ArrowRightSmall color="#666" />
              </Link>
            )
          })}
        </div>
      </div>
    </>
  )
}
