export const StoreLink: React.FC = () => {
  return (
    <>
      <div className="md:my-0 mt-[10px] mb-0.5">
        便利なアプリ、インストールはこちらから
      </div>
      <div className="flex mb-4 md:mb-5">
        <a
          className="hover:border-transparent"
          href="https://itunes.apple.com/jp/app/id1022261165"
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="mx-0 mt-[10px] md:mr-1 mb-0 md:ml-2.5 w-28"
            src={`${process.env.IMG_SERVER}/common/app_store_logo_apple.svg`}
            alt="App Store からダウンロード"
          />
        </a>
        <a
          className="hover:border-transparent"
          href="https://play.google.com/store/apps/details?id=com.flierinc.flier"
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="w-40"
            src={`${process.env.IMG_SERVER}/common/app_store_logo_google.png`}
            alt="Google Play で手に入れよう"
          />
        </a>
      </div>
    </>
  )
}
