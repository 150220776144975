import { Item } from './'

export const ABOUT_ITEMS: Item[] = [
  {
    href: 'doc/company',
    title: '運営会社',
  },
  {
    href: 'https://www.wantedly.com/companies/flierinc',
    title: '採用情報',
  },
  {
    href: 'media/list',
    title: '掲載実績',
  },
  {
    href: 'release/list',
    title: 'お知らせ',
  },
  {
    href: 'doc/services',
    title: 'サービス',
  },
  {
    href: 'https://biz.flierinc.com/?utm_source=flierinc.com&utm_medium=referral&utm_campaign=flierinc.com',
    title: '法人導入について',
  },
  {
    href: 'https://labo.flierinc.com/about',
    title: '読書コミュニティ',
  },
  {
    href: 'doc/devices',
    title: '対応環境について',
  },
  {
    href: 'inquire',
    title: 'お問い合わせ',
  },
  {
    href: 'https://flierinc.tayori.com/q/flier',
    title: 'よくあるご質問',
  },
]

interface FeatureItem {
  href: string
  image: string
  title: string
  description: string
  isNew: boolean
}

export const FEATURE_ITEMS: FeatureItem[] = [
  {
    href: '/channel',
    image: 'icon_movie_filled.svg',
    title: '動画',
    description: '気軽に学べる\n10分動画コンテンツ',
    isNew: true,
  },
  {
    href: '/pickup/list/1',
    image: 'icon_recommend_filled.svg',
    title: 'イチオシの本',
    description: '編集部/出版社イチオシ、\n今日は何の日？',
    isNew: false,
  },
  {
    href: '/interview/list/1',
    image: 'icon_interview_filled.svg',
    title: 'インタビュー',
    description: '書籍の関係者へ\n編集部が直接取材',
    isNew: false,
  },
  {
    href: '/column/list/1',
    image: 'icon_special_column_filled.svg',
    title: 'スペシャルコラム',
    description: '週/月間ランキング、\n読書コミュニティetc...',
    isNew: false,
  },
  {
    href: '/diagnosis/list',
    image: 'icon_diagnosis_filled.svg',
    title: 'おすすめ要約診断',
    description: 'あなたにぴったりの\n要約をおすすめ',
    isNew: false,
  },
]
