import React from 'react'
import AnimateHeight from 'react-animate-height'

export interface Item {
  id?: string
  href: string
  title: string
  condition?: boolean
}

interface Props {
  items: Item[]
  open: boolean
  children?: React.ReactNode
}

function getHref(url: string): string {
  if (url.match('https')) return url
  return `/${url}`
}

export const MenuItems: React.FC<Props> = ({ items, open, children }) => {
  return (
    <AnimateHeight duration={500} height={open ? 'auto' : 0}>
      <ul className="inline">
        {items.map((item) => (
          <React.Fragment key={item.href}>
            {(item.condition === undefined || item.condition) && (
              <li className="pt-6 pb-6">
                <a id={item.id} href={getHref(item.href)}>
                  {item.title}
                </a>
              </li>
            )}
          </React.Fragment>
        ))}
      </ul>
      {children}
    </AnimateHeight>
  )
}
