/* eslint tailwindcss/no-custom-classname: off */
import { useSelector, useDispatch } from 'react-redux'
import {
  RootState,
  initialHeaderState,
  setOpen,
  AppDispatch,
  useGetUserInfoQuery,
} from '@/stores'
import { Srp, UserInfo } from '@/types'
import { getSrpStatus, isMhfg } from '@/utils/user'
import AnimateHeight from 'react-animate-height'
import Link from 'next/link'
import { logout } from '@/utils/user'
import { MenuIcon } from '@/components/icon'
import { UserProfileIcon } from '@/components/user/UserProfileIcon'

interface Props {
  user: UserInfo
}

const styles = {
  ddmenuMain: {
    left: '-280px',
    width: '320px',
    padding: '0px',
    boxShadow: 'none',
  },
}

const openstyles = {
  ddmenuMain: {
    left: '-280px',
    width: '320px',
    padding: '0px',
  },
}

export const UserMenu: React.FC<Props> = ({ user }) => {
  const srpStatus = getSrpStatus(user)
  const open = useSelector((state: RootState) => state.header.open)
  const dispatch = useDispatch<AppDispatch>()
  const { refetch } = useGetUserInfoQuery()
  const modalSytle = open.userMenu ? openstyles.ddmenuMain : styles.ddmenuMain

  const handleClickMenu = (e) => {
    e.stopPropagation()
    dispatch(setOpen({ ...initialHeaderState.open, userMenu: !open.userMenu }))
  }

  const handleLogoutClick = async () => {
    await logout()
    refetch()
  }

  return (
    <div
      className="menu ddmenu"
      id="user-menu"
      data-target="user-ddmenu"
      onClick={handleClickMenu}
    >
      <MenuIcon className="m-auto mt-2 sm:mt-[10px] w-[60%] sm:w-[70%] h-[60%] sm:h-[70%] text-fl-dark" />
      <div id="user-ddmenu" className="p-0 ddmenu-main" style={modalSytle}>
        <AnimateHeight duration={500} height={open.userMenu ? 'auto' : 0}>
          <div className="p-5 h-auto max-h-[80vh]">
            <div className="flexbox">
              <UserProfileIcon
                size={64}
                spSize={64}
                src={user.image}
                text={user.name}
              />

              <div className="ml10">
                <div id="user-name">{user.name}</div>
                {(srpStatus === Srp.OPEN_SRP ||
                  srpStatus === Srp.CLOSE_SRP) && (
                  <div id="user-srp-id">@{user.srp_id}</div>
                )}
              </div>
            </div>
            <div id="user-course">
              {user.labo_id > 0 && user.course.id == 3 ? (
                <div>book laboプラン</div>
              ) : user.plan.display_name && user.plan.name ? (
                <div>{user.plan.name}</div>
              ) : user.biz_group_id > 0 ? (
                <div>{user.biz_group_name}</div>
              ) : (
                <div>{user.course.name}</div>
              )}
            </div>
            <div id="user-history">
              <Link href="/mypage">
                <div>マイページ</div>
              </Link>
            </div>
            {user.labo_id <= 0 &&
              user.biz_group_id <= 0 &&
              user.course.id != 3 && (
                <div id="user-upgrade">
                  <a href="/user/upgrade">アップグレード</a>
                </div>
              )}
            <div id="user-edit">
              <Link href="/user/edit">
                <div>登録内容の編集</div>
              </Link>
            </div>
            <div id="user-voice">
              <Link href="/user/voice">
                <div>音声再生の設定</div>
              </Link>
            </div>
            {/* 法人IDがみずほの場合は表示しない*/}
            {!isMhfg(user) && (
              <>
                {user.pay_type != 5 && (
                  <>
                    {(user?.biz_group_id > 0 &&
                      user?.biz_group_is_show_reset_payment == false) || (
                      <div id="user-payment">
                        <Link href="/user/payment_reset">支払い方法の変更</Link>
                      </div>
                    )}
                    {(user?.biz_group_id > 0 &&
                      user?.biz_group_is_show_receipt == false) || (
                      <div id="user-receipt">
                        <Link href="/receipt">
                          <div>領収書発行</div>
                        </Link>
                      </div>
                    )}
                  </>
                )}
                {(user?.biz_group_id > 0 &&
                  user?.biz_group_is_show_social_connection == false) || (
                  <div id="user-social">
                    <a href="/user/connection">ソーシャル連携</a>
                  </div>
                )}
              </>
            )}
            <div id="user-logout">
              <Link href="/logout">
                <div className="cursor-pointer" onClick={handleLogoutClick}>
                  ログアウト
                </div>
              </Link>
            </div>
          </div>
        </AnimateHeight>
      </div>
    </div>
  )
}
