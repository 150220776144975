import { useRouter } from 'next/router'
import { useEffect, useRef } from 'react'
import LoadingBar, { IProps, LoadingBarRef } from 'react-top-loading-bar'

/**
 * @see https://github.com/klendi/react-top-loading-bar#properties
 */
export const TopLoadingBar: React.FC<IProps> = ({
  progress = 0,
  color = '#1A6DCC',
  shadow = true,
  height = 2,
  background = 'transparent',
  style = null,
  containerStyle = {},
  shadowStyle = {},
  transitionTime = 300,
  loaderSpeed = 500,
  waitingTime = 1000,
  className = '',
  containerClassName = '',
  onLoaderFinished = null,
}) => {
  const router = useRouter()
  const ref = useRef<LoadingBarRef>(null)
  const handleStart = () => {
    ref.current.continuousStart()
  }
  const handleStop = () => {
    ref.current.complete()
  }

  useEffect(() => {
    router.events.on('routeChangeStart', handleStart)
    router.events.on('routeChangeComplete', handleStop)
    router.events.on('routeChangeError', handleStop)

    return () => {
      router.events.off('routeChangeStart', handleStart)
      router.events.off('routeChangeComplete', handleStop)
      router.events.off('routeChangeError', handleStop)
    }
  }, [])
  return (
    <LoadingBar
      ref={ref}
      progress={progress}
      color={color}
      shadow={shadow}
      height={height}
      background={background}
      style={style}
      containerStyle={containerStyle}
      shadowStyle={shadowStyle}
      transitionTime={transitionTime}
      loaderSpeed={loaderSpeed}
      waitingTime={waitingTime}
      className={className}
      containerClassName={containerClassName}
      onLoaderFinished={onLoaderFinished}
    />
  )
}
