/* eslint tailwindcss/no-custom-classname: off */
import { useEffect, useState } from 'react'
export const ScrollTop: React.FC = () => {
  const [show, setShow] = useState(false)
  const [style, setStyle] = useState({})

  const returnTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  const handleScroll = () => {
    if (window.scrollY > 500) {
      setShow(true)
    } else {
      setShow(false)
    }

    const footerTop = document.getElementsByTagName('footer')[0].offsetTop
    const scrollCheck = footerTop - window.outerHeight
    if (window.scrollY > scrollCheck) {
      setStyle({ position: 'relative', bottom: '' })
    } else {
      setStyle({ position: 'fixed', bottom: '0' })
    }
  }

  useEffect(() => {
    window.addEventListener(`scroll`, handleScroll)
    return () => window.removeEventListener(`scroll`, handleScroll)
  })
  return (
    <>
      {show && (
        <div className="page-top" onClick={returnTop} style={style}></div>
      )}
    </>
  )
}
