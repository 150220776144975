/* istanbul ignore file */
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { setReferrer } from '@/stores/referrerSlice'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '@/stores'

/**
 * URLが変わるたびにflier_access_log.jsonに記録
 */
export const useAccessLog = () => {
  const router = useRouter()
  const dispatch = useDispatch<AppDispatch>()
  const previousUrl = useSelector((state: RootState) => state.referrer.url)
  // URLが変わるたびに実行
  useEffect(() => {
    // flier_access_log.jsonに記録するためreferrerを保持しておく
    dispatch(setReferrer(router.asPath))

    const referrer = previousUrl
      ? `${process.env.WWW_SERVER}${previousUrl}`
      : document.referrer
    const requestPath = router.asPath
    postAccessLog(requestPath, referrer)
  }, [router.asPath])
}

/**
 * flier_access_log.jsonに記録
 *
 * ex.) ポップアップを開いたときに記録するなど
 */
export const postAccessLog = (requestPath: string, referrer = '') => {
  const body = { requestPath, referrer }

  fetch(process.env.WWW_SERVER + '/access_log', {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(body),
  })
}

/**
 * 指定のクエリを追加し、ホスト名なしのパスとクエリストリングのみを取得
 *
 * @param string url ex.) 'https://dev.local:3210/summary/123?hoge=h'
 * @param string key ex.) 'foo'
 * @param string value ex.) 'foo_value'
 * @return string ex.) '/summary/123?hoge=h&foo=foo_value'
 */
export const getPathWithUpdatedQuery = (
  url: string,
  key: string,
  value: string
): string => {
  try {
    const u = new URL(url)
    if (!key) return u.pathname
    u.searchParams.set(key, value)
    return u.pathname + u.search
  } catch (e) {
    return url
  }
}
