import { useState, createContext, useCallback, useEffect } from 'react'
import { TabValue } from './Tab'

interface Props {
  value: number
  onChange?: (tabIndex: number) => void // タブ切り替え時に実行したい関数
  children?: React.ReactNode
  tabsClassName?: string
  tabClassName?: string
  labelClassName?: string
  firstTabClassName?: string // 先頭のタブ固有のスタイル
  lastTabClassName?: string // 最後のタブ固有のスタイル
  tabActiveClassName?: string
  showLabelWithIcon?: boolean
}

export const TabContext = createContext({
  active: null,
  addTab: null,
})

export const Tabs: React.FC<Props> = ({
  value,
  onChange,
  children,
  tabsClassName,
  tabClassName,
  labelClassName,
  firstTabClassName,
  lastTabClassName,
  tabActiveClassName = '',
  showLabelWithIcon = true,
}) => {
  const [active, setActive] = useState(value)
  const [tabs, setTabs] = useState<TabValue[]>([])

  useEffect(() => {
    setActive(value)
  }, [value])

  const onClick = (event, index) => {
    setActive(index)
    onChange && onChange(index)
  }

  const addTab = useCallback((tab: TabValue) => {
    setTabs((tabs) => {
      // 開発中にレンダリングするたびにタブが増えてしまうのを防ぐための判定
      if (tabs.findIndex((item) => item.label === tab.label) >= 0) {
        return tabs
      } else {
        return [...tabs, tab]
      }
    })
  }, [])

  return (
    <TabContext.Provider value={{ active, addTab }}>
      <div className={`flex w-full bg-white ${tabsClassName}`}>
        {tabs.map((tab, i) => (
          <div
            className={[
              'flex ml-4 cursor-pointer border-gray-700 mx-auto my-0 px-4',
              tabClassName,
              active === i && `border-b-2 ${tabActiveClassName}`,
              i === 0 && firstTabClassName,
              i + 1 === tabs.length && lastTabClassName,
            ].join(' ')}
            key={tab.label}
            onClick={(e) => onClick(e, i)}
          >
            <div className="flex items-center w-full">
              <div className="mr-auto">{tab.icon}</div>
              <div
                className={[
                  'text-[17px] font-bold mr-auto',
                  showLabelWithIcon && 'pl-6 pc',
                  labelClassName,
                ].join(' ')}
              >
                {tab.label}
              </div>
            </div>
          </div>
        ))}
      </div>
      {children}
    </TabContext.Provider>
  )
}
