/* eslint tailwindcss/no-custom-classname: off */
import { useSelector, useDispatch } from 'react-redux'
import { RootState, initialHeaderState, setOpen } from '@/stores'

export const ModalBackdrop: React.FC = () => {
  const open = useSelector((state: RootState) => state.header.open)
  const dispatch = useDispatch()

  const handleClick = () => {
    dispatch(setOpen(initialHeaderState.open))
  }

  if (!open.modalBackdrop) return null

  return <div className="modal-backdrop fade in" onClick={handleClick}></div>
}
