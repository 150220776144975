/* eslint tailwindcss/no-custom-classname: off */
import { Link as FlierLink } from './Link'
import Link from 'next/link'
import { getSrpStatus } from '@/utils/user'
import { CourseId, Srp } from '@/types'
import { StoreLink } from './StoreLink'
import { useUser } from '@/hooks/useUser'
import { isFacility } from '@/utils/user'
import { styles } from './styles'

export const PcFooter: React.FC = () => {
  const user = useUser()
  const srpStatus = getSrpStatus(user)
  const handleClickFacebookFollow = () => {
    window.open('http://www.facebook.com/flierinc')
  }
  const handleClickTwitterFollow = () => {
    window.open(
      'http://twitter.com/intent/follow?source=followbutton&variant=1.0&screen_name=flierinc'
    )
  }
  return (
    <>
      <div className="container pc">
        <ul className="inline">
          <li>
            <div>要約を探す</div>
            <ul className="inline footer-group">
              <li>
                <ul>
                  <li>
                    <Link href="/category/1">スキルアップ・キャリア</Link>
                  </li>
                  <li>
                    <Link href="/category/2">自己啓発・マインド</Link>
                  </li>
                  <li>
                    <Link href="/category/3" prefetch={false}>
                      生産性・時間管理
                    </Link>
                  </li>
                  <li>
                    <Link href="/category/4" prefetch={false}>
                      リーダーシップ・マネジメント
                    </Link>
                  </li>
                  <li>
                    <Link href="/category/5" prefetch={false}>
                      経営戦略
                    </Link>
                  </li>
                  <li>
                    <Link href="/category/6" prefetch={false}>
                      起業・イノベーション
                    </Link>
                  </li>
                  <li>
                    <Link href="/category/7" prefetch={false}>
                      人事
                    </Link>
                  </li>
                  <li>
                    <Link href="/category/8" prefetch={false}>
                      マーケティング
                    </Link>
                  </li>
                  <li>
                    <Link href="/category/9" prefetch={false}>
                      産業・業界
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <ul>
                  <li>
                    <Link href="/category/10" prefetch={false}>
                      グローバル
                    </Link>
                  </li>
                  <li>
                    <Link href="/category/11" prefetch={false}>
                      政治・経済
                    </Link>
                  </li>
                  <li>
                    <Link href="/category/12" prefetch={false}>
                      ファイナンス
                    </Link>
                  </li>
                  <li>
                    <Link href="/category/13" prefetch={false}>
                      テクノロジー・IT
                    </Link>
                  </li>
                  <li>
                    <Link href="/category/14" prefetch={false}>
                      サイエンス
                    </Link>
                  </li>
                  <li>
                    <Link href="/category/15" prefetch={false}>
                      リベラルアーツ
                    </Link>
                  </li>
                  <li>
                    <Link href="/category/16">健康・フィットネス</Link>
                  </li>
                  <li>
                    <Link href="/category/17" prefetch={false}>
                      トレンド
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <div>flierについて</div>
            <div className="footer-group">
              <ul>
                <li>
                  <Link href="/doc/company">運営会社</Link>
                </li>
                <li>
                  <a
                    href="https://www.wantedly.com/companies/flierinc"
                    target="wantedly"
                  >
                    採用情報
                  </a>
                </li>
                <li>
                  <FlierLink path="/media/list">掲載実績</FlierLink>
                </li>
                <li>
                  <FlierLink path="/release/list">お知らせ</FlierLink>
                </li>
                <li>
                  <FlierLink path="/doc/services">サービス</FlierLink>
                </li>
                <li>
                  <FlierLink path="https://biz.flierinc.com/?utm_source=flierinc.com&utm_medium=referral&utm_campaign=flierinc.com">
                    法人導入について
                  </FlierLink>
                </li>
                <li>
                  <a href="https://labo.flierinc.com/about">
                    読書コミュニティについて
                  </a>
                </li>
                <li>
                  <FlierLink path="/doc/devices">対応環境について</FlierLink>
                </li>
                <li>
                  <FlierLink path="/inquire">お問い合わせ</FlierLink>
                </li>
                <li>
                  <a
                    href="https://flierinc.tayori.com/q/flier"
                    target="_blank"
                    rel="noreferrer"
                  >
                    よくあるご質問
                  </a>
                </li>
              </ul>
            </div>
          </li>
          <li>
            {!isFacility(user?.email) && (
              <div>
                <StoreLink />
              </div>
            )}
            {(user?.biz_group_id > 0 &&
              user?.biz_group_is_show_social_connection == false) || (
              <>
                <div>flierをフォロー</div>
                <div id="footer-follow">
                  <button
                    type="button"
                    onClick={handleClickFacebookFollow}
                    className="share-button facebook"
                  ></button>
                  <button
                    type="button"
                    onClick={handleClickTwitterFollow}
                    className="share-button-x"
                  ></button>
                </div>
              </>
            )}
          </li>
        </ul>
        <div id="footer-logo">
          <img
            src={`${process.env.IMG_SERVER}/common/footer_logo.png`}
            className="inline"
            alt=""
          />
        </div>
      </div>
      <div className="copyright pc">
        <ul className="container inline">
          <li>
            <FlierLink path="/doc/terms">利用規約</FlierLink>
          </li>
          <li>
            <FlierLink path="/doc/privacy">プライバシーポリシー</FlierLink>
          </li>
          <li>
            <FlierLink path="/doc/security">情報セキュリティ基本方針</FlierLink>
          </li>
          {(srpStatus == Srp.OPEN_SRP ||
            srpStatus == Srp.CLOSE_SRP ||
            !user?.result) && (
            <li>
              <FlierLink path="/doc/guideline">ガイドライン</FlierLink>
            </li>
          )}
          <li>
            <FlierLink path="/doc/transaction">
              特定商取引法に基づく表記
            </FlierLink>
          </li>

          {!isFacility(user?.email) && (
            <>
              {user?.plan?.withdraw === 'withdraw' && (
                <li>
                  <Link href="/user/withdraw" prefetch={false}>
                    {user.pay_type == 5 && user.course.id > 1
                      ? 'マイメニュー解約'
                      : '解約/退会'}
                  </Link>
                </li>
              )}
              {user?.result &&
                user?.plan?.withdraw !== 'withdraw' &&
                user?.plan?.withdraw !== 'restrict' && (
                  <li>
                    <Link href={user?.plan?.withdraw ?? ''} prefetch={false}>
                      解約
                    </Link>
                  </li>
                )}
              {user?.course?.id === CourseId.gold &&
                user.plan.restrict_upgrade == 0 &&
                user?.labo_id === 0 && (
                  <li>
                    <FlierLink path="/user/upgrade">料金プラン変更</FlierLink>
                  </li>
                )}
            </>
          )}
          <li>Copyright &copy; Flier Inc. all rights reserved.</li>
        </ul>
      </div>
      <style jsx>{styles}</style>
    </>
  )
}
