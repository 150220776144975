/* eslint tailwindcss/no-custom-classname: off */
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { initialHeaderState, modalFadeIn, setOpen, RootState } from '@/stores'
import { isMobile, isTablet } from 'react-device-detect'

export const AnonymousUserMenu: React.FC = () => {
  const dispatch = useDispatch()
  const [isSp, setIsSp] = useState(false)
  const showRegist = useSelector(
    (state: RootState) => state.headerOption.showRegist
  )
  useEffect(() => {
    setIsSp(isMobile && !isTablet)
  }, [])

  const handleClickLogin = (e) => {
    e.stopPropagation()
    dispatch(
      setOpen({
        ...initialHeaderState.open,
        modalBackdrop: true,
        modalLoginForm: modalFadeIn,
      })
    )
  }

  if (isSp) {
    return (
      <>
        <div className="p-3 mr-1">
          <a
            className="flex flex-none flex-grow-0 order-none items-center font-flier text-xs not-italic font-bold leading-7 text-fl-dark hover:text-fl-dark focus:text-fl-dark hover:no-underline focus:no-underline hover:outline-none focus:outline-none"
            data-toggle="modal"
            data-target="#login-form"
            onClick={handleClickLogin}
          >
            ログイン
          </a>
        </div>
        {showRegist && (
          <a href="/welcome?via=header" className="mr-[3px] btn btn-fl-regist">
            <div className="contents">
              <div className="icon pc"></div>
              <div className="text-box">
                <div className="text">会員登録</div>
                <div className="now-campaign">7日間無料!</div>
              </div>
            </div>
          </a>
        )}
      </>
    )
  }

  return (
    <>
      {showRegist && (
        <a href="/welcome?via=header" className="mr-3 btn btn-fl-regist">
          <div className="contents">
            <div className="icon pc"></div>
            <div className="text-box">
              <div className="text">会員登録</div>
              <div className="now-campaign">7日間無料!</div>
            </div>
          </div>
        </a>
      )}

      <button
        type="button"
        data-toggle="modal"
        data-target="#login-form"
        className="btn btn-fl-login"
        onClick={handleClickLogin}
      >
        <span>
          <span>ログイン</span>
        </span>
      </button>
    </>
  )
}
