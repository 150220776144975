import { useEffect } from 'react'
import { useRouter } from 'next/router'

declare const window: Window['window'] & {
  dataLayer: Record<string, unknown>[]
}

/*
 * Google Analyticsを発火＆ページタイトルを正しく表示するためのイベント送信
 */
export const useGoogleAnalytics = (): void => {
  const router = useRouter()

  const setPageViewTitle = () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: 'pageview', // 'pageview'はGoogleTagManagerのトリガーのイベント名と連動している
      pageTitle: document.title, // キー名の'pageTitle'はGoogleTagManagerのデータレイヤー変数名と連動している
    })
  }

  // routeChangeCompleteは初回アクセス・再読込時には発火しないため、初回のみ追加で実行
  useEffect(() => {
    setPageViewTitle()
  }, [])

  useEffect(() => {
    router.events.on('routeChangeComplete', setPageViewTitle)
    return () => {
      router.events.off('routeChangeComplete', setPageViewTitle)
    }
  }, [router.events])
}
