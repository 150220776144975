/* eslint tailwindcss/no-custom-classname: off */
import Link from 'next/link'
import AnimateHeight from 'react-animate-height'
import { useSelector, useDispatch } from 'react-redux'
import { RootState, initialHeaderState, setOpen, AppDispatch } from '@/stores'
import { UserInfo } from '@/types'
import { MenuIcon } from '@/components/icon'

interface Props {
  user: UserInfo
}

const styles = {
  ddmenuMain: {
    left: '-280px',
    width: '320px',
    padding: '0px',
    boxShadow: 'none',
  },
}

const openstyles = {
  ddmenuMain: {
    left: '-280px',
    width: '320px',
    padding: '0px',
  },
}

export const LimitedUrlUserMenu: React.FC<Props> = ({ user }) => {
  const open = useSelector((state: RootState) => state.header.open)
  const dispatch = useDispatch<AppDispatch>()
  const modalSytle = open.userMenu ? openstyles.ddmenuMain : styles.ddmenuMain

  const handleClickMenu = (e) => {
    e.stopPropagation()
    dispatch(setOpen({ ...initialHeaderState.open, userMenu: !open.userMenu }))
  }

  return (
    <div
      className="menu ddmenu"
      id="user-menu"
      data-target="user-ddmenu"
      onClick={handleClickMenu}
    >
      <MenuIcon className="w-[100%] h-[100%]" />
      <div id="user-ddmenu" className="p-0 ddmenu-main" style={modalSytle}>
        <AnimateHeight duration={500} height={open.userMenu ? 'auto' : 0}>
          <div className="p-5 h-[80vh] max-h-[600px]">
            <div className="flexbox">
              <img
                src={user.image}
                alt="ユーザーアイコン"
                className="object-cover w-[64px] h-[64px] rounded-full"
              />
            </div>
            <div id="user-course">
              <div>{user.course.name}</div>
            </div>
            <div id="user-history">
              <Link href="/mypage">
                <div>マイページ</div>
              </Link>
            </div>
            <div id="user-voice">
              <Link href="/user/voice">
                <div>音声再生の設定</div>
              </Link>
            </div>
          </div>
        </AnimateHeight>
      </div>
    </div>
  )
}
