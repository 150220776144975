import { useDispatch } from 'react-redux'
import {
  setHeaderOption,
  HeaderOptionState,
  initialHeaderOptionState,
} from '@/stores/headerOptionSlice'
import { useEffect } from 'react'
import { useRouter } from 'next/router'

type headerOption = Partial<HeaderOptionState>
export const useHeaderOption = ({
  showRegist = true,
  showLogin = true,
}: headerOption = initialHeaderOptionState): void => {
  const dispatch = useDispatch()
  const router = useRouter()
  useEffect(() => {
    dispatch(setHeaderOption({ showRegist, showLogin }))
  }, [router.asPath])
}
