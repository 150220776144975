/* eslint tailwindcss/no-custom-classname: off */
import { useState } from 'react'
import { getSrpStatus } from '@/utils/user'
import { CourseId, Srp } from '@/types'
import { Link as FlierLink } from './Link'
import { StoreLink } from './StoreLink'
import Link from 'next/link'
import { useUser } from '@/hooks/useUser'
import { isFacility } from '@/utils/user'
import { styles } from './styles'

export const MobileFooter: React.FC = () => {
  const [open, setOpen] = useState({
    findSummary: 'hide',
    feature: 'hide',
    about: 'hide',
  })
  const user = useUser()
  const srpStatus = getSrpStatus(user)

  const handleClickFacebookFollow = () => {
    window.open('http://www.facebook.com/flierinc')
  }
  const handleClickTwitterFollow = () => {
    window.open(
      'http://twitter.com/intent/follow?source=followbutton&variant=1.0&screen_name=flierinc'
    )
  }
  const handleClickMenu = (key: string) => {
    const isOpen = open[key] !== 'hide'
    setOpen({ ...open, [key]: isOpen ? 'hide' : '' })
  }

  return (
    <>
      <div className="container sp">
        <div id="footer-logo">
          <img
            src={`${process.env.IMG_SERVER}/common/footer_logo.png`}
            className="inline"
            alt=""
          />
        </div>
        <ul className="mobpad">
          <li className="relative">
            <div
              className={`${
                open.findSummary === 'hide'
                  ? 'plus-right-aqua'
                  : 'minus-right-aqua'
              } toggle-footer`}
              data-target="#footer-mobile1"
              onClick={() => handleClickMenu('findSummary')}
            >
              <span>要約を探す</span>
            </div>
            <ul className={open.findSummary} id="footer-mobile1">
              <li className="submenu">
                <Link href="/summary/list">すべての要約</Link>
              </li>
              <li className="submenu">
                <Link href="/summary/masterpiece">
                  いま読んでおきたい話題書
                </Link>
              </li>
              <li className="submenu">
                <Link href="/summary/free">無料で読める要約</Link>
              </li>
              <li className="submenu">
                <Link href="/summary/ranking">要約ランキング</Link>
              </li>
              <li className="submenu">
                <Link href="/diagnosis/list">おすすめ要約診断</Link>
              </li>
            </ul>
          </li>
          <li className="relative">
            <div
              className={`${
                open.feature === 'hide' ? 'plus-right-aqua' : 'minus-right-aqua'
              } toggle-footer`}
              data-target="#footer-mobile2"
              onClick={() => handleClickMenu('feature')}
            >
              <span>特集</span>
            </div>
            <ul className={open.feature} id="footer-mobile2">
              <li className="submenu">
                <Link href="/interview/list/1" prefetch={false}>
                  インタビュー
                </Link>
              </li>
              <li className="submenu">
                <Link href="/column/list/1" prefetch={false}>
                  スペシャルコラム
                </Link>
              </li>
              <li className="submenu">
                <Link href="/pickup/list/1">イチオシの本</Link>
              </li>
            </ul>
          </li>
          <li className="relative">
            <div
              className={`${
                open.about === 'hide' ? 'plus-right-aqua' : 'minus-right-aqua'
              } toggle-footer`}
              data-target="#footer-mobile3"
              onClick={() => handleClickMenu('about')}
            >
              flierについて
            </div>
            <ul className={open.about} id="footer-mobile3">
              <li className="submenu">
                <Link href="/doc/company">運営会社</Link>
              </li>
              <li className="submenu">
                <a
                  href="https://www.wantedly.com/companies/flierinc"
                  target="wantedly"
                >
                  採用情報
                </a>
              </li>
              <li className="submenu">
                <FlierLink path="/media/list">掲載実績</FlierLink>
              </li>
              <li className="submenu">
                <FlierLink path="/release/list">お知らせ</FlierLink>
              </li>
              <li className="submenu">
                <FlierLink path="/doc/services">サービス</FlierLink>
              </li>
              <li className="submenu">
                <FlierLink path="https://biz.flierinc.com/?utm_source=flierinc.com&utm_medium=referral&utm_campaign=flierinc.com">
                  法人導入について
                </FlierLink>
              </li>
              <li className="submenu">
                <a
                  href="https://labo.flierinc.com/about"
                  target="_blank"
                  rel="noreferrer"
                >
                  読書コミュニティ
                </a>
              </li>
              <li className="submenu">
                <FlierLink path="/doc/devices">対応環境について</FlierLink>
              </li>
              <li className="submenu">
                <FlierLink path="/inquire">お問い合わせ</FlierLink>
              </li>
              <li className="submenu">
                <a
                  href="https://flierinc.tayori.com/q/flier"
                  target="_blank"
                  rel="noreferrer"
                >
                  よくあるご質問
                </a>
              </li>
            </ul>
          </li>
          <li>
            <FlierLink path="/doc/terms">利用規約</FlierLink>
          </li>
          <li>
            <FlierLink path="/doc/privacy">プライバシーポリシー</FlierLink>
          </li>
          <li>
            <FlierLink path="/doc/security">情報セキュリティ基本方針</FlierLink>
          </li>
          {(srpStatus === Srp.OPEN_SRP ||
            srpStatus === Srp.CLOSE_SRP ||
            !user?.result) && (
            <li>
              <FlierLink path="/doc/guideline">ガイドライン</FlierLink>
            </li>
          )}
          <li>
            <FlierLink path="/doc/transaction">
              特定商取引法に基づく表記
            </FlierLink>
          </li>
          {!isFacility(user?.email) && (
            <>
              {user?.plan?.withdraw === 'withdraw' && (
                <li>
                  <Link href="/user/withdraw" prefetch={false}>
                    {user.pay_type == 5 && user.course.id > 1
                      ? 'マイメニュー解約'
                      : '解約/退会'}
                  </Link>
                </li>
              )}
              {user?.result &&
                user?.plan?.withdraw !== 'withdraw' &&
                user?.plan?.withdraw !== 'restrict' && (
                  <li>
                    <Link href={user?.plan?.withdraw ?? ''} prefetch={false}>
                      解約
                    </Link>
                  </li>
                )}
              {user?.course?.id === CourseId.gold &&
                user.plan.restrict_upgrade == 0 &&
                user?.labo_id === 0 && (
                  <li>
                    <FlierLink path="/user/upgrade">料金プラン変更</FlierLink>
                  </li>
                )}
            </>
          )}
        </ul>

        <div className="mobpad">
          {!isFacility(user?.email) && (
            <div>
              <StoreLink />
            </div>
          )}
          {(user?.biz_group_id > 0 &&
            user?.biz_group_is_show_social_connection == false) || (
            <>
              <div>flierをフォロー</div>
              <div id="footer-follow">
                <button
                  type="button"
                  onClick={handleClickFacebookFollow}
                  className="share-button facebook"
                ></button>
                <button
                  type="button"
                  onClick={handleClickTwitterFollow}
                  className="share-button-x"
                ></button>
              </div>
            </>
          )}
        </div>
      </div>
      <div className="copyright sp">
        <div className="mobpad">
          Copyright &copy; Flier Inc. all rights reserved.
        </div>
      </div>
      <style jsx>{styles}</style>
    </>
  )
}
