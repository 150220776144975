import { md5 } from '@/utils/format'
import { initialStyles, Modal } from '@/components/modal/Modal'
import { useGetUserInfoQuery } from '@/stores'
import { Srp } from '@/types'
import { getSrpStatus, isFacility } from '@/utils/user'
import { useEffect, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { PostUserInfo } from '@/api/user'
import { useRouter } from 'next/router'

export const TutorialModal: React.FC = () => {
  const router = useRouter()
  const { refetch } = useGetUserInfoQuery()
  const [open, setOpen] = useState(false)
  const [openMypage, setOpenMypage] = useState(false)
  const { data: user } = useGetUserInfoQuery()
  const modalStyle = {
    ...initialStyles,
    content: {
      ...initialStyles.content,
      width: isMobile ? 'auto' : '600px',
    },
  }

  useEffect(() => {
    if (
      user &&
      !isFacility(user?.email) &&
      getSrpStatus(user) !== Srp.OFF_SRP &&
      user?.use_srp &&
      !user?.is_confirmed &&
      router.asPath === '/'
    ) {
      setOpen(true)
    }
  }, [user, router.asPath])

  const onClickNext = async () => {
    setOpen(false)
    // isOpening, canSearch, isConfirmed, isConfiguredをセットする
    const newUserInfo = {
      isOpening: true,
      canSearch: true,
      isConfirmed: true,
      isConfigured: true,
      introduction: user.introduction,
      pickupId: user?.pickup?.id ?? 0,
      isPublicStand: user?.is_public_stand,
      isPublicReadingData: user?.is_public_reading_data,
      email: user?.email,
      password: '',
      name: user?.name,
      srpId: user?.srp_id,
      birth: user?.birth,
      genderId: user?.gender?.id,
      prefId: user?.pref?.id ?? 0,
      jobId: user?.job?.id,
      roleId: user?.role?.id,
      favorites: user?.favorites ?? '',
      magazine1: user?.magazine1 ?? 0,
      token: user?.token,
      csrfToken: md5(user.token),
    }
    await PostUserInfo(newUserInfo)
    refetch()
    setTimeout(() => {
      setOpenMypage(true)
    }, 300)
  }

  return (
    <>
      <Modal open={open} style={modalStyle} onRequestClose={onClickNext}>
        <img
          src={`${process.env.IMG_SERVER}/top/pc_popup_tutorial_memo.png`}
          height="300"
        />
        <div className="p-12 text-center">
          <div className="text-xl sm:text-2xl font-bold">
            読書のメモ機能で
            <br />
            学びや感想を共有できます
          </div>
          <div className="py-4 px-2 font-flier text-sm sm:text-base text-fl-black">
            自分のメモを残すだけでなく、他の人のメモを見ることができます。
          </div>
          <button
            className="py-4 sm:py-3 px-20 sm:px-20 text-xs sm:text-base font-bold text-white bg-fl-blue rounded-full"
            onClick={onClickNext}
          >
            次へ
          </button>
        </div>
      </Modal>
      <Modal open={openMypage} style={modalStyle}>
        <img
          src={`${process.env.IMG_SERVER}/top/popup_tutorial_setting.webp`}
          height="300"
        />
        <div className="py-6 px-6 pb-7 text-center">
          <div className="mb-3 text-xl sm:text-2xl font-bold">
            プロフィール画像を設定しましょう
          </div>
          <div className="px-2 mb-3 font-flier text-base text-fl-black">
            マイページでは、本棚の公開情報も設定いただけます
          </div>
          <button
            className="py-3 px-14 mb-5 text-sm sm:text-base font-bold text-white bg-fl-blue rounded-full"
            onClick={() => {
              router.push('/user/edit')
              setOpenMypage(false)
            }}
          >
            マイページで設定する
          </button>
          <button
            className="block my-0 mx-auto text-sm sm:text-base font-bold text-fl-blue !no-underline"
            onClick={() => setOpenMypage(false)}
          >
            後で設定する
          </button>
        </div>
      </Modal>
    </>
  )
}
