/* eslint tailwindcss/no-custom-classname: off */
import { Logo, FindSummary, Features, About, SearchBox, UserTools } from './'
import { useRouter } from 'next/router'

export const Container: React.FC = () => {
  const router = useRouter()
  if (
    router.pathname == '/bookseller/[address]' ||
    (router.pathname == '/summary/[summary_id]' && !!router.query.code) ||
    router.pathname.match(/campaigns/)
  ) {
    return (
      <div className="container">
        <div className="mx-auto">
          <Logo />
        </div>
      </div>
    )
  }
  return (
    <div className="container">
      <div className="flexbox">
        <Logo />
        <FindSummary />
        <Features />
        <About />
        <SearchBox
          keyword={
            router.pathname == '/search' ? (router.query.keyword as string) : ''
          }
        />
        <UserTools />
      </div>
    </div>
  )
}
