import css from 'styled-jsx/css'

export const styles = css`
  .social-button-x {
    margin: 0 auto;
    padding: 0;
    border-radius: 5px;
    width: 138px;
    height: 34px;
    text-align: left;
    font-size: 15px;
    color: #fff;
    position: relative;
    margin-bottom: 5px;

    &:before {
      position: absolute;
      background-image: url(${process.env.IMG_SERVER}/common/X.svg);
      background-repeat: no-repeat;
      background-size: 30px 30px;
      width: 30px;
      height: 30px;
      content: '';
      border-radius: 3px;
      border-right: 1px solid #ffffff33;
    }

    > span {
      display: inline-block;
      padding-top: 3px;
      padding-left: 40px;
      height: 100%;
    }

    &.x {
      border: 2px solid #080404;
      background-color: #080404;
    }
  }
`
