import { FallbackProps } from 'react-error-boundary'
import { Header } from '../Header'
import { Footer } from '../Footer/Footer'

// eslint-disable-next-line
function ErrorFallback({ error }: FallbackProps) {
  return (
    <div>
      <Header />
      <div className="pt-[103px]">エラーが発生しました</div>
      <a href="/">トップへ戻る</a>
      <Footer />
    </div>
  )
}
export default ErrorFallback
