import { useState, useCallback } from 'react'
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function useTriangle() {
  const [left, setLeft] = useState(null)
  const ref = useCallback((node) => {
    if (node !== null) {
      const rect = node.getBoundingClientRect()
      const left = window.pageXOffset + rect.width / 2 - 5 - 10 // 5は三角の大きさ(10px)/2,  10はddmenuのpadding/2
      setLeft(left)
    }
  }, [])
  return [left, ref]
}
