/* eslint tailwindcss/no-custom-classname: off */

import Link from 'next/link'
import { useSelector, useDispatch } from 'react-redux'
import {
  RootState,
  initialHeaderState,
  setOpen,
  modalFadeIn,
  useGetUserInfoQuery,
} from '@/stores'
import { postLogin } from '@/api/user'
import { encrypt } from '@/utils/format'
import { useState } from 'react'
import { useRouter } from 'next/router'
import { checkUrlMatches } from '@/utils/url'
import { styles } from './styles'

export const LoginForm: React.FC = () => {
  const dispatch = useDispatch()
  const openState = useSelector((state: RootState) => state.header.open)
  const router = useRouter()
  const { redirect } = router.query as { redirect: string }
  const { refetch } = useGetUserInfoQuery()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const handleClickModal = (e) => {
    e.stopPropagation()
  }

  const handleLoginClick = async () => {
    const res = await postLogin(email, encrypt(password))
    if (res.result) {
      refetch()
      // リダイレクトパラメータがある場合はリダイレクト
      if (redirect && checkUrlMatches(redirect)) {
        dispatch(setOpen(initialHeaderState.open))
        router.push(redirect)
      } else {
        window.location.reload()
      }
    } else {
      dispatch(
        setOpen({
          ...openState,
          modalLoginError: modalFadeIn,
        })
      )
    }
  }

  const handleCloseClick = () => {
    dispatch(setOpen(initialHeaderState.open))
  }

  const handleSNSClick = (e) => {
    const { type } = e.currentTarget.dataset
    location.href = `${process.env.API_SERVER}/sns/login/${type}/?referer=${router.asPath}`
  }

  const handleOnSubmit = (e) => {
    e.preventDefault()
  }

  return (
    <>
      <div
        id="login-form"
        className={`${openState.modalLoginForm.class} overflow-x-hidden overflow-y-auto`}
        role="dialog"
        tabIndex={-1}
        style={openState.modalLoginForm.css}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content" onClick={handleClickModal}>
            <div className="modal-header">
              <span>ログイン</span>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                onClick={handleCloseClick}
              >
                &times; 閉じる
              </button>
            </div>
            <div className="modal-body">
              <form className="form-horizontal" onSubmit={handleOnSubmit}>
                <div className="fl-form-line form-group">
                  <label htmlFor="email" className="require">
                    メールアドレス/
                    <br />
                    ログインID
                  </label>
                  <div className="fl-form pb20">
                    {openState.modalLoginForm.show && (
                      <input
                        type="text"
                        className="form-control"
                        id="email"
                        placeholder="メールアドレスを入力してください"
                        autoComplete="username"
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    )}
                  </div>
                </div>
                <div className="fl-form-line form-group">
                  <label htmlFor="password" className="require">
                    パスワード
                  </label>
                  <div className="fl-form pb20">
                    {openState.modalLoginForm.show && (
                      <input
                        type="password"
                        className="form-control"
                        id="password"
                        placeholder="パスワードを入力してください"
                        autoComplete="current-password"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                    )}
                  </div>
                </div>
                <div className="form-group right mr10 font13">
                  <Link href="/user/forgotpwd">
                    <div onClick={() => handleCloseClick()}>
                      パスワードを忘れた方はこちら
                    </div>
                  </Link>
                </div>
                <div className="form-group center mt30">
                  <button
                    type="button"
                    className="mr-1 btn btn-fl-sm btn-fl-cancel arrow-left-white"
                    data-dismiss="modal"
                    onClick={handleCloseClick}
                  >
                    <span className="pr20 pl30">キャンセル</span>
                  </button>
                  <button
                    type="submit"
                    className="btn btn-fl-sm btn-fl-submit arrow-right-white"
                    onClick={handleLoginClick}
                  >
                    <span className="pr50 pl40">ログイン</span>
                  </button>
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <div>SNSでログイン</div>
              <div className="mb10">
                <button
                  className="link social-button facebook"
                  data-url={`${process.env.API_SERVER}/sns/login/facebook`}
                  data-type="facebook"
                  onClick={handleSNSClick}
                  style={{ marginRight: '4px' }}
                >
                  <span>facebook</span>
                </button>
                <button
                  className="link social-button-x x"
                  data-url={`${process.env.API_SERVER}/sns/login/twitter`}
                  data-type="twitter"
                  onClick={handleSNSClick}
                >
                  <span>X(Twitter)</span>
                </button>
              </div>
              <div className="text-[12px]">
                ログインすることで、
                <a href="/doc/terms" target="_blank" rel="noreferrer">
                  利用規約
                </a>
                、
                <a href="/doc/biz_terms" target="_blank" rel="noreferrer">
                  利用規約(法人)
                </a>
                、
                <a href="/doc/privacy" target="_blank" rel="noreferrer">
                  プライバシーポリシー
                </a>
                、
                <a href="doc/guideline" target="_blank" rel="noreferrer">
                  ガイドライン
                </a>
                、
                <a href="/doc/newsletter" target="_blank" rel="noreferrer">
                  ニュースレター
                </a>
                に同意したものとみなされます。
              </div>
            </div>
          </div>
        </div>
      </div>
      <style jsx>{styles}</style>
    </>
  )
}
