/* eslint tailwindcss/no-custom-classname: off */
import React, { useEffect, useState } from 'react'
import { RootState, initialHeaderState, setOpen } from '@/stores'
import { useSelector, useDispatch } from 'react-redux'
import { DownAllow } from '@/components/symbol/DownAllow'
import AnimateHeight from 'react-animate-height'
import { FEATURE_ITEMS as items } from './constants'
import { useTriangle } from './hooks/useTriangle'
import { New } from '@/components/label/New'
import Link from 'next/link'
import { isDesktop } from 'react-device-detect'

export const Features: React.FC = () => {
  const open = useSelector((state: RootState) => state.header.open)
  const dispatch = useDispatch()
  const [left, ref] = useTriangle()
  const [show, setShow] = useState(false)

  const handleClickMenu = (e) => {
    e.stopPropagation()
    dispatch(setOpen({ ...initialHeaderState.open, features: !open.features }))
  }

  useEffect(() => {
    // PCのみ表示
    setShow(isDesktop)
  }, [])

  return (
    show && (
      <div ref={ref} className="menu ddmenu" onClick={handleClickMenu}>
        <span
          className={`menu-label ${open.features && 'menu-label-selected'}`}
        >
          特集
          <DownAllow />
        </span>
        <div
          className="flexbox triangle"
          style={{ visibility: open.features ? 'visible' : 'hidden', left }}
        ></div>
        <div className="ddmenu-main" id="menu3">
          <AnimateHeight duration={500} height={open.features ? 'auto' : 0}>
            <div className="pt-5"></div>
            <ul className="p-0 m-0 w-full">
              {items.map((item) => (
                <React.Fragment key={item.href}>
                  <li className="inline-block last:m-0 mr-6 align-top">
                    <div className="item">
                      <Link href={item.href} className="hover:no-underline">
                        <div className="item-link">
                          <img
                            src={`${process.env.IMG_SERVER}/header/${item.image}`}
                            alt=""
                          />
                          <div className="title">
                            <span className="item-title">{item.title}</span>
                            {item.isNew && <New />}
                          </div>
                          <div className="description">
                            {item.description.split('\n').map((v, i) => (
                              <React.Fragment key={i}>
                                {v}
                                <br />
                              </React.Fragment>
                            ))}
                          </div>
                        </div>
                      </Link>
                    </div>
                  </li>
                </React.Fragment>
              ))}
            </ul>
            <div className="pb-5"></div>
          </AnimateHeight>
        </div>
      </div>
    )
  )
}
