/* eslint tailwindcss/no-custom-classname: off */
import { Space } from '@/components/layout'
import { useHeaderOption } from '@/hooks/useHeaderOption'
import { Container, Breadcrumb } from './'
import { useRouter } from 'next/router'

export const Header: React.FC = () => {
  useHeaderOption()
  const router = useRouter()
  /* 学割更新、学割登録画面はヘッダーを表示しない */
  if (
    router.asPath.includes('student/plan') ||
    router.asPath.endsWith('student/update') ||
    router.asPath.includes('tplan/')
  ) {
    return <></>
  }
  return (
    <>
      <header>
        <div id="global" className="flexbox back-white">
          <Space />
          <Container />
          <Space />
        </div>
        <Breadcrumb />
      </header>
      <div className="overall"></div>
    </>
  )
}
