import { useSelector } from 'react-redux'
import { MobileFooter } from './MobileFooter'
import { PcFooter } from './PcFooter'
import { ScrollTop } from './ScrollTop'
import { RootState } from '@/stores'
import { useRouter } from 'next/router'

export const Footer: React.FC = () => {
  const router = useRouter()

  const footer = useSelector((state: RootState) => state.footer)
  if (!footer.show) return null
  /* 学割更新、学割登録画面はフッターを表示しない */
  if (
    router.asPath.includes('student/plan') ||
    router.asPath.endsWith('student/update') ||
    router.asPath.includes('tplan/')
  ) {
    return <></>
  }

  return (
    <>
      <ScrollTop />
      <footer>
        <MobileFooter />
        <PcFooter />
      </footer>
    </>
  )
}
