/* eslint tailwindcss/no-custom-classname: off */
import { RootState } from '@/stores/store'
import { initialHeaderState, setOpen } from '@/stores/headerSlice'
import { useSelector, useDispatch } from 'react-redux'
import { DownAllow } from '@/components/symbol/DownAllow'
import { MenuItems, ABOUT_ITEMS } from './'
import { useTriangle } from './hooks/useTriangle'

export const About: React.FC = () => {
  const open = useSelector((state: RootState) => state.header.open)
  const dispatch = useDispatch()
  const [left, ref] = useTriangle()

  const handleClickMenu = (e) => {
    e.stopPropagation()
    dispatch(setOpen({ ...initialHeaderState.open, about: !open.about }))
  }
  return (
    <div ref={ref} className="menu ddmenu pc" onClick={handleClickMenu}>
      <span className={`menu-label ${open.about && 'menu-label-selected'}`}>
        flierについて
        <DownAllow />
      </span>
      <div
        className="flexbox pc triangle"
        style={{ visibility: open.about ? 'visible' : 'hidden', left }}
      ></div>
      <div className="ddmenu-main">
        <MenuItems items={ABOUT_ITEMS} open={open.about} />
      </div>
    </div>
  )
}
