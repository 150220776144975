/* eslint tailwindcss/no-custom-classname: off */
import css from 'styled-jsx/css'
import { GroupIcon } from '@/components/icon'
import Link from 'next/link'

interface Props {
  backGroundPosition: string
  height: string
}

const style = css`
  #user-business {
    position: relative;
    width: 44px;
    padding: 0;
  }

  #user-business > a {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }
`

export const BusinessPersons: React.FC<Props> = ({
  backGroundPosition,
  height,
}) => {
  return (
    <div
      className="menu"
      id="user-business"
      style={{ height: height, backgroundPosition: backGroundPosition }}
    >
      <Link href="/business_group/comments">
        <GroupIcon
          className="mx-auto mt-[10px] sm:mt-[14px] sm:ml-[-10px] w-[80%] h-[70%] text-fl-dark"
          viewBox="-35 0 200 200"
        />
      </Link>
      <style jsx>{style}</style>
    </div>
  )
}
