import { SearchDark } from '@/components/icon'
import { Dispatch, SetStateAction } from 'react'
interface Props {
  setModalOpen: Dispatch<SetStateAction<boolean>>
  setShowModal: Dispatch<SetStateAction<boolean>>
}
export const SearchIcon: React.FC<Props> = ({ setModalOpen, setShowModal }) => {
  const onClick = () => {
    setModalOpen(true)
    setShowModal(true)
  }

  return (
    <div onClick={onClick}>
      <SearchDark />
    </div>
  )
}
