/* eslint tailwindcss/no-custom-classname: off */
import React from 'react'
import Link from 'next/link'
import { RootState } from '@/stores/store'
import { initialHeaderState, setOpen } from '@/stores/headerSlice'
import { useSelector, useDispatch } from 'react-redux'
import { DownAllow } from '@/components/symbol/DownAllow'
import { Categories } from './'
import { useTriangle } from './hooks/useTriangle'
import AnimateHeight from 'react-animate-height'

interface Item {
  href: string
  title: string
}

const FIND_SUMMARY_ITEMS: Item[] = [
  { href: '/summary/ranking', title: '要約ランキング' },
  { href: '/summary/masterpiece', title: 'いま読んでおきたい話題書' },
  { href: '/summary/free', title: '無料で読める要約' },
  { href: '/summary/list', title: 'すべての要約' },
]

export const FindSummary: React.FC = () => {
  const open = useSelector((state: RootState) => state.header.open)
  const dispatch = useDispatch()
  const [left, ref] = useTriangle()

  const handleClickMenu = (e) => {
    e.stopPropagation()
    dispatch(
      setOpen({ ...initialHeaderState.open, findSummary: !open.findSummary })
    )
  }
  return (
    <div
      className="menu ddmenu pc"
      onClick={handleClickMenu}
      id="menu1parent"
      ref={ref}
    >
      <span
        className={`menu-label ${open.findSummary && 'menu-label-selected'}`}
      >
        要約を探す
        <DownAllow />
      </span>
      <div
        className="flexbox pc triangle"
        style={{ visibility: open.findSummary ? 'visible' : 'hidden', left }}
      ></div>
      <div className="ddmenu-main">
        <AnimateHeight duration={500} height={open.findSummary ? 'auto' : 0}>
          <ul className="inline">
            {FIND_SUMMARY_ITEMS.map((item) => (
              <li key={item.href} className="pt-6 pb-6">
                <Link href={item.href}>
                  <div>{item.title}</div>
                </Link>
              </li>
            ))}
          </ul>
          <div className="ddmenu-header mt20">本のカテゴリー</div>
          <div className="ddmenu-group">
            <Categories />
          </div>
        </AnimateHeight>
      </div>
    </div>
  )
}
