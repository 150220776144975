import { ArrowRightSmall, MenuCategory } from '@/components/icon'
import Link from 'next/link'

interface Props {
  onClick: () => void
}

export const Category: React.FC<Props> = ({ onClick }) => {
  // カテゴリ項目
  const categories = [
    { title: 'スキルアップ・キャリア', path: '/category/1' },
    { title: '自己啓発・マインド', path: '/category/2' },
    { title: '生産性・時間管理', path: '/category/3' },
    { title: 'リーダーシップ・マネジメント', path: '/category/4' },
    { title: '経営戦略', path: '/category/5' },
    { title: '起業・イノベーション', path: '/category/6' },
    { title: '人事', path: '/category/7' },
    { title: 'マーケティング', path: '/category/8' },
    { title: '産業・業界', path: '/category/9' },
    { title: 'グローバル', path: '/category/10' },
    { title: '政治・経済', path: '/category/11' },
    { title: 'ファイナンス', path: '/category/12' },
    { title: 'テクノロジー・IT', path: '/category/13' },
    { title: 'サイエンス', path: '/category/14' },
    { title: 'リベラルアーツ', path: '/category/15' },
    { title: '健康・フィットネス', path: '/category/16' },
    { title: 'トレンド', path: '/category/17' },
  ]

  return (
    <>
      <div className="border-b-8 border-fl-gray1">
        <div className="flex flex-none flex-grow-0 order-1 py-3 pr-0 pl-4 m-0">
          <MenuCategory className="pr-2" />
          <span className="font-flier text-[10px] xs:text-xs not-italic font-bold tracking-tight leading-relaxed text-fl-black">
            カテゴリ
          </span>
        </div>
        <div className="flex flex-row flex-wrap items-start p-0">
          {categories.map((item, i) => {
            return (
              <Link
                key={`search-form-category-${i}`}
                href={item.path}
                className="flex justify-between py-3 pr-[18px] pl-4 w-full border-0 border-t border-r border-fl-gray1"
                onClick={onClick}
              >
                <span className="flex-none flex-grow-0 order-none font-flier text-[10px] xs:text-xs not-italic font-normal leading-relaxed text-fl-dark">
                  {item.title}
                </span>
                <ArrowRightSmall color="#666" />
              </Link>
            )
          })}
        </div>
      </div>
    </>
  )
}
