/* eslint tailwindcss/no-custom-classname: off */
import Link from 'next/link'
import { UserMenu } from './UserMenu'
import { Srp, UserInfo } from '@/types'
import { getSrpStatus, isFacility, isLimitedUrlUser } from '@/utils/user'
import { BusinessPersons } from './BusinessPersons'
import { isMobile, isTablet } from 'react-device-detect'
import { useGetUserCheckQuery } from '@/stores/apiService'
import { LimitedUrlUserMenu } from './LimitedUrlUserMenu'
import { NotificationsIcon } from '@/components/icon'

interface Props {
  user: UserInfo
}

export const LoginUserMenu: React.FC<Props> = ({ user }) => {
  const { data } = useGetUserCheckQuery()
  const srpStatus = getSrpStatus(user)
  const badgeCount = data?.notification > 0 ? data?.notification : ''

  if (isFacility(user?.email) && !isLimitedUrlUser(user?.email)) {
    return null
  }

  return (
    <>
      {/* 法人SRP CLOSEの場合のみビジネスパーソンが3名並んだユーザ（みんなの学びメモページへの導線）を表示させる
       */}
      {user.biz_group_id > 0 && srpStatus === Srp.CLOSE_SRP && (
        <BusinessPersons
          backGroundPosition={isMobile && !isTablet ? '0 12px' : '0 25px'}
          height={isMobile && !isTablet ? '50px' : '72px'}
        />
      )}
      {(srpStatus === Srp.OPEN_SRP ||
        srpStatus === Srp.CLOSE_SRP ||
        (srpStatus === Srp.OFF_SRP && user.biz_group_id > 0)) && (
        <div className="menu" id="user-notice">
          <Link href="/user/notice">
            <div>
              <span className="badge badge-pill badge-danger">
                {badgeCount}
              </span>
              <NotificationsIcon className="m-auto mt-[10px] sm:mt-[20px] sm:mr-3 w-[60%] sm:w-[70%] h-[60%] sm:h-[70%] text-fl-dark" />
            </div>
          </Link>
        </div>
      )}
      {isLimitedUrlUser(user?.email) ? (
        <LimitedUrlUserMenu user={user} />
      ) : (
        <UserMenu user={user} />
      )}
    </>
  )
}
