import css from 'styled-jsx/css'

export const styles = css`
  .share-button-x {
    position: relative;
    height: 30px;
    width: 30px;
    background-size: 30px 30px;
    background-image: url(${process.env.IMG_SERVER}/common/X.svg);
    background-repeat: no-repeat;
    border: 0;
    margin-right: 5px;
    border-radius: 50%;

    &:hover {
      top: -3px;
    }
  }
`
